import React from 'react';

const Service = () => {
    return (
        <div className="service-area py-60">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 mx-auto">
                    <div className="site-heading text-center">
                        <span className="site-title-tagline">Services</span>
                        <h2 className="site-title">What We <span>Offer</span></h2>
                        <div className="heading-divider"></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                        <div className="service-img">
                            <img src="../assets/img/service/01.jpg" alt="thumb"/>
                        </div>
                        <div className="service-icon">
                            <i className="flaticon-screwdriver"></i>
                        </div>
                        <div className="service-content">
                            <h3 className="service-title">
                                <a href="noaction">Brake &amp; Repair Service</a>
                            </h3>
                            <p className="service-text">
                                There are many variations of passages orem psum available but the majority have
                                suffered alteration in some form by injected.
                            </p>
                            <div className="service-arrow">
                                <a href="noaction" className="theme-btn">Read More<i className="fas fa-arrow-right-long"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                        <div className="service-img">
                            <img src="../assets/img/service/02.jpg" alt="thumb"/>
                        </div>
                        <div className="service-icon">
                            <i className="flaticon-gear-1"></i>
                        </div>
                        <div className="service-content">
                            <h3 className="service-title">
                                <a href="noaction">Engine &amp; Transmission</a>
                            </h3>
                            <p className="service-text">
                                There are many variations of passages orem psum available but the majority have
                                suffered alteration in some form by injected.
                            </p>
                            <div className="service-arrow">
                                <a href="noaction" className="theme-btn">Read More<i className="fas fa-arrow-right-long"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                        <div className="service-img">
                            <img src="../assets/img/service/03.jpg" alt="thumb"/>
                        </div>
                        <div className="service-icon">
                            <i className="flaticon-temperature"></i>
                        </div>
                        <div className="service-content">
                            <h3 className="service-title">
                                <a href="noaction">Heating &amp; AC Repairs</a>
                            </h3>
                            <p className="service-text">
                                There are many variations of passages orem psum available but the majority have
                                suffered alteration in some form by injected.
                            </p>
                            <div className="service-arrow">
                                <a href="noaction" className="theme-btn">Read More<i className="fas fa-arrow-right-long"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                        <div className="service-img">
                            <img src="../assets/img/service/04.jpg" alt="thumb"/>
                        </div>
                        <div className="service-icon">
                            <i className="flaticon-smart-light"></i>
                        </div>
                        <div className="service-content">
                            <h3 className="service-title">
                                <a href="noaction">Engine Upgrades</a>
                            </h3>
                            <p className="service-text">
                                There are many variations of passages orem psum available but the majority have
                                suffered alteration in some form by injected.
                            </p>
                            <div className="service-arrow">
                                <a href="noaction" className="theme-btn">Read More<i className="fas fa-arrow-right-long"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                        <div className="service-img">
                            <img src="../assets/img/service/05.jpg" alt="thumb"/>
                        </div>
                        <div className="service-icon">
                            <i className="flaticon-tire"></i>
                        </div>
                        <div className="service-content">
                            <h3 className="service-title">
                                <a href="noaction">Tire &amp; Auto Parts</a>
                            </h3>
                            <p className="service-text">
                                There are many variations of passages orem psum available but the majority have
                                suffered alteration in some form by injected.
                            </p>
                            <div className="service-arrow">
                                <a href="noaction" className="theme-btn">Read More<i className="fas fa-arrow-right-long"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                        <div className="service-img">
                            <img src="../assets/img/service/06.jpg" alt="thumb"/>
                        </div>
                        <div className="service-icon">
                            <i className="flaticon-drive-thru"></i>
                        </div>
                        <div className="service-content">
                            <h3 className="service-title">
                                <a href="noaction">Auto Electric</a>
                            </h3>
                            <p className="service-text">
                                There are many variations of passages orem psum available but the majority have
                                suffered alteration in some form by injected.
                            </p>
                            <div className="service-arrow">
                                <a href="noaction" className="theme-btn">Read More<i className="fas fa-arrow-right-long"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Service;
